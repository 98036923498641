import React, {useState} from "react";
import TabellaDiConversione from "./TabellaDiConversione";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import {dietaSimona, dietaFedericoLow} from "../Data/databaseDiete";
import CardPasto from "./CardPasto";

export const DietTable = () => {

    const [today, setToday] = useState<Date>(new Date());
    const [dayName, setDayName] = useState<string>(
        today.toLocaleDateString(
            'it-IT',
            {weekday: 'long'}
        ).charAt(0).toUpperCase() + today.toLocaleDateString('it-IT', {weekday: 'long'}).slice(1)
    )

    const [pastiOggiFiglio] = useState(dietaFedericoLow.find(pasto => pasto.giorno === dayName));
    const [pastiGiornoMadre] = useState(dietaSimona.find(pasto => pasto.giorno === dayName));

    const user1: string = "Federico";
    const user2: string = "Simona";

    const [currentPasti, setCurrentPasti] = useState(pastiOggiFiglio);

    const [currentUser, setCurrentUser] = useState<string>(user1);

    const handleChange = (person: string) => {
        const oggi = new Date()
        setToday(oggi)
        const nextDayName = oggi.toLocaleDateString(
            'it-IT',
            {weekday: 'long'}
        ).charAt(0).toUpperCase() + oggi.toLocaleDateString('it-IT', {weekday: 'long'}).slice(1)
        setDayName(nextDayName)

        if (person === "federico") {
            setCurrentPasti(pastiOggiFiglio)
            setCurrentUser(user1)
        }
        if (person === "simona") {
            setCurrentPasti(pastiGiornoMadre)
            setCurrentUser(user2)
        }
    }

    const nextDay = () => {
        const next = new Date(today.getTime() + 86400000)
        setToday(next)
        const nextDayName = next.toLocaleDateString(
            'it-IT',
            {weekday: 'long'}
        ).charAt(0).toUpperCase() + next.toLocaleDateString('it-IT', {weekday: 'long'}).slice(1)
        setDayName(nextDayName)
        if (currentUser === user1) {
            setCurrentPasti(dietaFedericoLow.find(pasto => pasto.giorno === nextDayName))
        } else {
            setCurrentPasti(dietaSimona.find(pasto => pasto.giorno === nextDayName))
        }
    }

    const prevDay = () => {
        const prev = new Date(today.getTime() - 86400000)
        setToday(prev)
        const prevDayName = prev.toLocaleDateString(
            'it-IT',
            {weekday: 'long'}
        ).charAt(0).toUpperCase() + prev.toLocaleDateString('it-IT', {weekday: 'long'}).slice(1)
        setDayName(prevDayName)
        if (currentUser === user1) {
            setCurrentPasti(dietaFedericoLow.find(pasto => pasto.giorno === prevDayName))
        } else {
            setCurrentPasti(dietaSimona.find(pasto => pasto.giorno === prevDayName))
        }
    }

    return (
        <div className={"flex flex-col space-y-4 my-4"}>
            {/*TOP*/}
            <header className={"flex items-center justify-center w-full"}>
                <h1 className={"font-bold text-4xl"}>Dieta {currentUser}</h1>
            </header>
            {/*HEADER*/}
            <div className="flex flex-col md:flex-row gap-2 items-center justify-between">
                <div className="sm:flex-auto">
                    <h1 className="text-3xl font-semibold text-gray-900">{dayName}</h1>
                </div>
                <div className={"flex gap-2"}>
                    <button
                        type="button"
                        onClick={prevDay}
                        className="inline-flex flex-1 items-center justify-center rounded-md border border-transparent bg-teal-600 p-2 text-sm font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:w-auto"
                    >
                        <ChevronLeftIcon className="h-5 w-5 text-white" aria-hidden="true"/>
                    </button>
                    <button
                        type="button"
                        onClick={() => handleChange("federico")}
                        className={currentUser === user1 ?
                            "inline-flex flex-1 items-center justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:w-auto" :
                            "inline-flex flex-1 items-center justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                        }
                    >
                        Federico
                    </button>
                    <button
                        type="button"
                        onClick={() => handleChange("simona")}
                        className={currentUser === user2 ?
                            "inline-flex flex-1 items-center justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:w-auto" :
                            "inline-flex flex-1 items-center justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                        }
                    >
                        Simona
                    </button>
                    <button
                        type="button"
                        onClick={nextDay}
                        className="inline-flex flex-1 items-center justify-center rounded-md border border-transparent bg-teal-600 p-2 text-sm font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:w-auto"
                    >
                        <ChevronRightIcon className="h-5 w-5 text-white" aria-hidden="true"/>
                    </button>
                </div>

            </div>
            {/*BODY*/}
            <div className={"flex flex-col gap-4"}>
                <CardPasto titolo={"Colazione"} pasti={currentPasti?.colazione}/>
                <CardPasto titolo={"Spuntino"} pasti={currentPasti?.spuntinoMattina}/>
                <CardPasto titolo={"Pranzo"} pasti={currentPasti?.pranzo}/>
                <CardPasto titolo={"Spuntino"} pasti={currentPasti?.spuntinoPomeriggio}/>
                <CardPasto titolo={"Cena"} pasti={currentPasti?.cena}/>
            </div>
            {/*FOOTER*/}
            <TabellaDiConversione user={currentUser}/>
        </div>
    )
}
