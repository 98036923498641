import React from 'react';
import {DietTable} from "../components/DietTable";

const Home = () => {
    return (
        <div className={"flex justify-center bg-gradient-to-br from-orange-100 via-rose100 to-green-100"}>
            <div className={"flex flex-col pt-4 w-11/12 justify-center items-center"}>
                <div className={"w-full mb-4"}>

                    <DietTable/>
                </div>
            </div>
        </div>
    );
};

export default Home;
