import React, {useState} from 'react';
import {conversionePesiFiglio, conversionePesiMadre} from "../Data/databaseDiete";

interface TabellaDiConversioneProps {
    user: string
}

const TabellaDiConversione = ({user}: TabellaDiConversioneProps) => {

    const conversionePesi = user === "Federico" ? conversionePesiFiglio : conversionePesiMadre;

    const tipiPasta: string[] = [
        "Pasta Corta",
        "Pasta Lunga",
        "Riso",
        "Gnocchi",
    ]

    const [currentFattoreConversione, setCurrentFattoreConversione] = useState<number>(conversionePesi[0].fattoreConversione);
    const [currentPeso, setCurrentPeso] = useState<number>(0);

    return (
        <div className={"flex flex-col space-y-4"}>
            <div className={"text-xl font-semibold"}>
                Tabella di conversione
            </div>
            <div className={"border-x border-t rounded-t-md shadow"}>
                <div className={"flex bg-gray-100 border-b"}>
                    {/*TOP*/}
                    <div className={"flex-1 p-2"}/>
                    <div className={"flex-1 border-l text-center font-medium p-2"}>
                        Crudo
                    </div>
                    <div className={"flex-1 border-l font-medium text-center p-2"}>
                        Cotto
                    </div>
                </div>
                {
                    conversionePesi.map((cibo: CaratteristicheCibi, index) => (
                        <div className={"flex border-b bg-white"} key={index}>
                            {/*TOP*/}
                            <div className={"flex-1 text-center font-medium p-2"}>
                                {cibo.nome}
                            </div>
                            <div className={"flex-1 border-l font-medium text-center p-2"}>
                                {cibo.peso}g
                            </div>
                            <div className={"flex-1 border-l font-medium text-center p-2"}>
                                {(cibo.peso * cibo.fattoreConversione).toFixed()}g
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className={"flex flex-col space-y-2"}>
                <div className={"text-xl font-semibold"}>
                    Convertitore
                </div>
                <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                    Peso Crudo
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">g</span>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center">
                        <label htmlFor="currency" className="sr-only">
                            Tipo Pasta
                        </label>
                        <select
                            name="tipo_pasta"
                            onChange={(e) => setCurrentFattoreConversione(
                                conversionePesi[tipiPasta.indexOf(e.target.value)].fattoreConversione
                            )}
                            className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                            {
                                tipiPasta.map((tipoPasta: string, index) => (
                                    <option key={index}>{tipoPasta}</option>
                                ))
                            }
                        </select>
                    </div>
                    <input
                        type="number"
                        name="price"
                        id="price"
                        onChange={(e) => setCurrentPeso(
                            e.target.value === "" ? 0 : parseInt(e.target.value)
                        )}
                        className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="0.00"
                    />
                </div>
                <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                    Peso Cotto
                </label>
                <div>
                    {currentPeso*currentFattoreConversione} g
                </div>
            </div>
        </div>
    );
};

export default TabellaDiConversione;
