interface Alimento {
    nome: string;
    quantita: string;
}


export const conversionePesiFiglio: CaratteristicheCibi[] = [
    {
        nome: "Pasta Corta",
        peso: 100,
        fattoreConversione: 2,
    },
    {
        nome: "Pasta Lunga",
        peso: 100,
        fattoreConversione: 2.4,
    },
    {
        nome: "Riso",
        peso: 100,
        fattoreConversione: 3.33,
    },
    {
        nome: "Gnocchi",
        peso: 190,
        fattoreConversione: 1.3,
    }
]
export const conversionePesiMadre: CaratteristicheCibi[] = [
    {
        nome: "Pasta Corta",
        peso: 60,
        fattoreConversione: 2,
    },
    {
        nome: "Pasta Lunga",
        peso: 60,
        fattoreConversione: 2.4,
    },
    {
        nome: "Riso",
        peso: 60,
        fattoreConversione: 3.33,
    },
    {
        nome: "Gnocchi",
        peso: 180,
        fattoreConversione: 1.3,
    }
]


interface DailyDiet {
    giorno: string;
    colazione: Alimento[];
    spuntinoMattina: Alimento[];
    pranzo: Alimento[];
    spuntinoPomeriggio: Alimento[];
    cena: Alimento[];
}

const giorni: string[] = [
    'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'
];

const alimentiFederico: { [key: string]: Alimento } = {
    latte: {nome: 'Latte', quantita: '200g'},
    marmellata: {nome: 'Marmellata', quantita: '15g'},
    pane30: {nome: 'Pane', quantita: '30g'},
    pane50: {nome: 'Pane', quantita: '50g'},
    pane100: {nome: 'Pane', quantita: '100g'},
    ricotta: {nome: 'Ricotta', quantita: '40g'},
    farro: {nome: 'Farro', quantita: '100g'},
    verdura: {nome: 'Verdura', quantita: '200g'},
    frutta: {nome: 'Frutta', quantita: '150g'},
    olio25: {nome: 'Olio', quantita: '25g'},
    olio30: {nome: 'Olio', quantita: '30g'},
    olio35: {nome: 'Olio', quantita: '35g'},
    oroSaiwa: {nome: 'Oro Saiwa', quantita: '30g'},
    pollo: {nome: 'Pollo', quantita: '110g'},
    prosciuttoCotto: {nome: 'Prosciutto Cotto', quantita: '25g'},
    pastariso: {nome: 'Pasta/Riso', quantita: '100g'},
    crackers: {nome: 'Crackers', quantita: '30g'},
    pesce: {nome: 'Pesce', quantita: '155g'},
    fetteBiscottate: {nome: 'Fette Biscottate', quantita: '30g'},
    bresaola: {nome: 'Bresaola', quantita: '25g'},
    uova: {nome: 'Uova', quantita: '2'},
    muesli: {nome: 'Muesli', quantita: '50g'},
    protein: {nome: 'Protein+', quantita: '85g'},
    gnocchi: {nome: 'Gnocchi', quantita: '190g'},
    yogurt: {nome: 'Yogurt', quantita: '125g'},
    fagioli: {nome: 'Fagioli', quantita: '120g'},
    tonno: {nome: 'Tonno', quantita: '100g'},
    filettoVitello: {nome: 'Filetto Vitello', quantita: '155g'},
    crudo40: {nome: 'Crudo', quantita: '40g'},
    crudo25: {nome: 'Crudo', quantita: '25g'},
    pizza: {nome: 'Pizza', quantita: '1'}
}


const alimentiSimona: { [key: string]: Alimento } = {
    yogurt: {nome: 'Yogurt', quantita: '200g'},
    cereali: {nome: 'Cereali', quantita: '40g'},
    frutto: {nome: 'Frutto', quantita: '200g'},
    pastariso: {nome: 'Pasta/Riso', quantita: '60g'},
    verdura: {nome: 'Verdura', quantita: '200g'},
    parmigiano: {nome: 'Parmigiano', quantita: '10g'},
    carneBianca: {nome: 'Carne Bianca', quantita: '150g'},
    pane80: {nome: 'Pane', quantita: '80g'},
    legumi: {nome: 'Legumi', quantita: '240g'},
    pesce: {nome: 'Pesce', quantita: '190g'},
    formaggio: {nome: 'Formaggio', quantita: '50g'},
    gnocchi: {nome: 'Gnocchi', quantita: '190g'},
    uova: {nome: 'Uova', quantita: '2'},
    carneRossa: {nome: 'Carne Rossa', quantita: '120g'},
    pizza: {nome: 'Pizza', quantita: '1'},
    birra: {nome: 'Birra', quantita: '1'},
    affettato: {nome: 'Affettato', quantita: '80g'},
    tonno: {nome: 'Tonno', quantita: '100g'},
    cornetto: {nome: 'Cornetto', quantita: '1'},
    cappuccino: {nome: 'Cappuccino', quantita: '1'},
    pastoLibero: {nome: 'Pasto Libero', quantita: 'No bis'},
}


const addDayToDiet = (day: string, colazione: Alimento[], spuntinoMattina: Alimento[], pranzo: Alimento[], spuntinoPomeriggio: Alimento[], cena: Alimento[]): DailyDiet => {
    return {
        giorno: day,
        colazione,
        spuntinoMattina,
        pranzo,
        spuntinoPomeriggio,
        cena
    };
};

// BULK
export const dietaFedericoBulk: DailyDiet[] = [
    addDayToDiet(
        giorni[0],
        [alimentiFederico.latte, alimentiFederico.marmellata, alimentiFederico.pane30],
        [alimentiFederico.pane50, alimentiFederico.ricotta],
        [alimentiFederico.farro, alimentiFederico.verdura, alimentiFederico.pane100, alimentiFederico.frutta, alimentiFederico.olio25],
        [alimentiFederico.oroSaiwa],
        [alimentiFederico.pollo, alimentiFederico.verdura, alimentiFederico.pane100, alimentiFederico.frutta, alimentiFederico.olio30]
    ),
    addDayToDiet(
        giorni[1],
        [alimentiFederico.latte, alimentiFederico.oroSaiwa, alimentiFederico.marmellata],
        [alimentiFederico.pane50, alimentiFederico.prosciuttoCotto],
        [alimentiFederico.pastariso, alimentiFederico.verdura, alimentiFederico.pane100, alimentiFederico.frutta, alimentiFederico.olio25],
        [alimentiFederico.crackers],
        [alimentiFederico.pesce, alimentiFederico.verdura, alimentiFederico.pane100, alimentiFederico.frutta, alimentiFederico.olio30]
    ),
    addDayToDiet(
        giorni[2],
        [alimentiFederico.latte, alimentiFederico.fetteBiscottate, alimentiFederico.marmellata],
        [alimentiFederico.pane50, alimentiFederico.bresaola],
        [alimentiFederico.farro, alimentiFederico.verdura, alimentiFederico.pane100, alimentiFederico.frutta, alimentiFederico.olio25],
        [alimentiFederico.oroSaiwa],
        [alimentiFederico.uova, alimentiFederico.verdura, alimentiFederico.pane100, alimentiFederico.frutta, alimentiFederico.olio30]
    ),
    addDayToDiet(
        giorni[3],
        [alimentiFederico.latte, alimentiFederico.muesli],
        [alimentiFederico.pane50, alimentiFederico.protein],
        [alimentiFederico.gnocchi, alimentiFederico.verdura, alimentiFederico.pane100, alimentiFederico.frutta, alimentiFederico.olio25],
        [alimentiFederico.yogurt],
        [alimentiFederico.fagioli, alimentiFederico.tonno, alimentiFederico.verdura, alimentiFederico.pane100, alimentiFederico.frutta, alimentiFederico.olio30]
    ),
    addDayToDiet(
        giorni[4],
        [alimentiFederico.latte, alimentiFederico.fetteBiscottate, alimentiFederico.marmellata],
        [alimentiFederico.pane50, alimentiFederico.crudo40],
        [alimentiFederico.pastariso, alimentiFederico.verdura, alimentiFederico.pane100, alimentiFederico.frutta, alimentiFederico.olio30],
        [alimentiFederico.yogurt],
        [alimentiFederico.filettoVitello, alimentiFederico.verdura, alimentiFederico.pane100, alimentiFederico.frutta, alimentiFederico.olio35]
    ),
    addDayToDiet(
        giorni[5],
        [alimentiFederico.latte, alimentiFederico.oroSaiwa, alimentiFederico.marmellata],
        [],
        [alimentiFederico.pastariso, alimentiFederico.crudo25, alimentiFederico.verdura, alimentiFederico.pane100, alimentiFederico.frutta, alimentiFederico.olio30],
        [alimentiFederico.crackers],
        [alimentiFederico.pizza]
    ),
    addDayToDiet(
        giorni[6],
        [{nome: 'Cornetto', quantita: '1'}],
        [],
        [{nome: 'Pasto Libero', quantita: 'No bis'}],
        [],
        [{
            nome: 'Protein+',
            quantita: '90g'
        }, alimentiFederico.verdura, alimentiFederico.pane100, alimentiFederico.frutta, {nome: 'Olio', quantita: '5g'}]
    )
];

// LOW
export const dietaFedericoLow: DailyDiet[] = [

    addDayToDiet(
        giorni[0],
        [{
            nome: 'Latte',
            quantita: '200g'
        }, {
            nome: "Cereali",
            quantita: "40g"
        }],
        [{
            nome: "Frutto",
            quantita: "200g"
        }],
        [{
            nome: "Pasta/Riso",
            quantita: "90g"
        }, {
            nome: "Verdura",
            quantita: "300g"
        }, {
            nome: "Pane",
            quantita: "50g"
        }, {
            nome: "Parmigiano",
            quantita: "10g"
        }],
        [{
            nome: "Frutto",
            quantita: "200g"
        }],
        [{
            nome: "Carne Bianca",
            quantita: "190g"
        }, {
            nome: "Verdura",
            quantita: "300g"
        }, {
            nome: "Pane",
            quantita: "80g"
        }],
    ),

    addDayToDiet(
        giorni[1],
        [{
            nome: "Yogurt",
            quantita: "200g"
        }, {
            nome: "Cereali",
            quantita: "40g"
        }],
        [{
            nome: "Frutto",
            quantita: "200g"
        }],
        [{
            nome: "Legumi",
            quantita: "240g"
        }, {
            nome: "Verdura",
            quantita: "300g"
        }, {
            nome: "Pane",
            quantita: "50g"
        }, {
            nome: "Parmigiano",
            quantita: "10g"
        }],
        [{
            nome: "Frutto",
            quantita: "200g"
        }],
        [{
            nome: "Pesce",
            quantita: "200g"
        }, {
            nome: "Verdura",
            quantita: "300g"
        }, {
            nome: "Pane",
            quantita: "80g"
        }],
    ),

    addDayToDiet(
        giorni[2],
        [{
            nome: "Yogurt",
            quantita: "200g"
        }, {
            nome: "Cereali",
            quantita: "40g"
        }],
        [{
            nome: "Frutto",
            quantita: "200g"
        }],
        [{
            nome: "Pasta/Riso",
            quantita: "90g"
        }, {
            nome: "Verdura",
            quantita: "300g"
        }, {
            nome: "Pane",
            quantita: "50g"
        }, {
            nome: "Parmigiano",
            quantita: "10g"
        }],
        [{
            nome: "Frutto",
            quantita: "200g"
        }],
        [{
            nome: "Formaggio",
            quantita: "70g"
        }, {
            nome: "Verdura",
            quantita: "300g"
        }, {
            nome: "Pane",
            quantita: "80g"
        }],
    ),

    addDayToDiet(
        giorni[3],
        [{
            nome: "Yogurt",
            quantita: "200g"
        }, {
            nome: "Cereali",
            quantita: "40g"
        }],
        [{
            nome: "Frutto",
            quantita: "200g"
        }],
        [{
            nome: "Gnocchi",
            quantita: "200g"
        }, {
            nome: "Verdura",
            quantita: "300g"
        }, {
            nome: "Pane",
            quantita: "50g"
        }, {
            nome: "Parmigiano",
            quantita: "10g"
        }],
        [{
            nome: "Frutto",
            quantita: "200g"
        }],
        [{
            nome: "Uova",
            quantita: "2"
        }, {
            nome: "Verdura",
            quantita: "300g"
        }, {
            nome: "Pane",
            quantita: "80g"
        }],
    ),

    addDayToDiet(
        giorni[4],
        [{
            nome: "Yogurt",
            quantita: "200g"
        }, {
            nome: "Cereali",
            quantita: "40g"
        }],
        [{
            nome: "Frutto",
            quantita: "200g"
        }],
        [{
            nome: "Legumi",
            quantita: "240g"
        }, {
            nome: "Verdura",
            quantita: "300g"
        }, {
            nome: "Pane",
            quantita: "50g"
        }, {
            nome: "Parmigiano",
            quantita: "10g"
        }],
        [{
            nome: "Frutto",
            quantita: "200g"
        }],
        [{
            nome: "Carne Rossa",
            quantita: "150g"
        }, {
            nome: "Verdura",
            quantita: "300g"
        }, {
            nome: "Pane",
            quantita: "80g"
        }],
    ),

    addDayToDiet(
        giorni[5],
        [{
            nome: "Yogurt",
            quantita: "200g"
        }],
        [],
        [{
            nome: "Affettato",
            quantita: "100g"
        }, {
            nome: "Verdura",
            quantita: "300g"
        }],
        [],
        [{
            nome: "Pizza",
            quantita: "1"
        }],
    ),

    addDayToDiet(
        giorni[6],
        [{
            nome: "Cornetto",
            quantita: "1"
        }, {
            nome: "Cappuccino",
            quantita: "1"
        }],
        [],
        [{
            nome: "Pasto Libero",
            quantita: "No bis"
        }],
        [],
        [{
            nome: "Tonno",
            quantita: "100g"
        }, {
            nome: "Verdura",
            quantita: "300g"
        }, {
            nome: "Pane",
            quantita: "80g"
        }],
    )
]

export const dietaSimona: DailyDiet[] = [
    addDayToDiet(
        giorni[0],
        [alimentiSimona.yogurt, alimentiSimona.cereali],
        [alimentiSimona.frutto],
        [alimentiSimona.pastariso, alimentiSimona.verdura, alimentiSimona.parmigiano],
        [alimentiSimona.frutto],
        [alimentiSimona.carneBianca, alimentiSimona.verdura, alimentiSimona.pane80]
    ),
    addDayToDiet(
        giorni[1],
        [alimentiSimona.yogurt, alimentiSimona.cereali],
        [alimentiSimona.frutto],
        [alimentiSimona.legumi, alimentiSimona.verdura],
        [alimentiSimona.frutto],
        [alimentiSimona.pesce, alimentiSimona.verdura, alimentiSimona.pane80]
    ),
    addDayToDiet(
        giorni[2],
        [alimentiSimona.yogurt, alimentiSimona.cereali],
        [alimentiSimona.frutto],
        [alimentiSimona.pastariso, alimentiSimona.verdura, alimentiSimona.parmigiano],
        [alimentiSimona.frutto],
        [alimentiSimona.formaggio, alimentiSimona.verdura, alimentiSimona.pane80]
    ),
    addDayToDiet(
        giorni[3],
        [alimentiSimona.yogurt, alimentiSimona.cereali],
        [alimentiSimona.frutto],
        [alimentiSimona.gnocchi, alimentiSimona.verdura, alimentiSimona.parmigiano],
        [alimentiSimona.frutto],
        [alimentiSimona.uova, alimentiSimona.verdura, alimentiSimona.pane80]
    ),
    addDayToDiet(
        giorni[4],
        [alimentiSimona.yogurt, alimentiSimona.cereali],
        [alimentiSimona.frutto],
        [alimentiSimona.legumi, alimentiSimona.verdura, alimentiSimona.parmigiano],
        [alimentiSimona.frutto],
        [alimentiSimona.carneRossa, alimentiSimona.verdura, alimentiSimona.pane80]
    ),
    addDayToDiet(
        giorni[5],
        [alimentiSimona.yogurt, alimentiSimona.cereali],
        [],
        [alimentiSimona.affettato, alimentiSimona.verdura],
        [],
        [alimentiSimona.tonno, alimentiSimona.verdura, alimentiSimona.pane80]
    ),
    addDayToDiet(
        giorni[6],
        [alimentiSimona.cornetto, alimentiSimona.cappuccino],
        [],
        [alimentiSimona.pastoLibero],
        [],
        [alimentiSimona.tonno, alimentiSimona.verdura, alimentiSimona.pane80]
    )
];


