import React from 'react';

const CardPasto = ({titolo, pasti}: any) => {
    return (
        <div className={"flex flex-col p-6 gap-3 border rounded-lg shadow-sm bg-white bg-opacity-70 backdrop-blur-xl"}>
            <div className={"text-2xl font-semibold"}>
                {titolo}
            </div>
            <div className={"flex flex-col divide-y"}>
                {
                    pasti.map((cibo: any, index: number) => (
                        <div key={index} className={"flex justify-between py-2"}>
                            <div>
                                {cibo.nome}
                            </div>
                            <div>
                                {cibo.quantita}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default CardPasto;
